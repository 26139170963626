import React from 'react'
import Logo from './grslogo.png'
import { Link } from 'react-router-dom'

function Roof({ setFormData, formData }) {
  return (
    <div className="bg-grGrey w-screen h-screen ease-linear">
      <div className="w-screen">
        <img className="w-64" src={Logo} alt="Green Ridge Solar" />
      </div>
      <div className="mx-24 h-full flex-1 flex-col justify-center mt-40">
        <div className="flex-1 flex-col justify-center content-center ">
          <div className="font-display text-5xl text-center text-white">
            Your Household Roof Type?
          </div>
        </div>
        <div className="flex justify-center my-4 ">
          <div className="hover:cursor-pointer">
            <Link to="/zip">
              <img
                src="https://greenridgesolar.com/wp-content/plugins/rooflead_clone1 (рабочий Грин)/static/img/roof1.png"
                alt="Icon roof"
                className="w-64 mx-8"
                onClick={() => setFormData({ ...formData, roof: 'roof1' })}
              />
            </Link>
          </div>
          <Link to="/zip">
            <img
              src="https://greenridgesolar.com/wp-content/plugins/rooflead_clone1 (рабочий Грин)/static/img/roof2.png"
              alt="Icon roof"
              className="w-64 mx-8"
              onClick={() => setFormData({ ...formData, roof: 'roof2' })}
            />
          </Link>
        </div>
        <div className="flex justify-center my-4"></div>
      </div>
    </div>
  )
}

export default Roof
