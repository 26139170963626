import React from 'react'
import Logo from './grslogo.png'
import { Link } from 'react-router-dom'

function Main() {
  return (
    <div className="bg-gray-300 w-screen h-screen">
      <div className="w-screen">
        <img className="w-64" src={Logo} alt="Green Ridge Solar" />
      </div>
      <div className="mx-24 h-full flex-1 flex-col justify-center mt-40">
        <div className="flex-1 flex-col justify-center content-center ">
          <div className="font-display text-5xl text-center">
            Your Solar Savings Are Inside
          </div>
          <div className="text-body text-center mx-auto mt-4 uppercase tracking-wide">
            With our convenient, one minute process, you can qualify your home
            and discover just how EASY it is to SAVE with solar!
          </div>
          <div className="text-body text-center w-1/2 mx-auto uppercase">
            Click below to get started.
          </div>
        </div>
        <div className="flex justify-center my-4">
          <Link to="/roof">
            <button className="bg-grGreen hover:bg-green-500 w-48 text-white text-lg font-bold  py-2 px-8 rounded uppercase">
              Start
            </button>
          </Link>
        </div>
        <div className="text-center w-1/3 mx-auto text-xs">
          Savings estimates based on national averages. Your system price and
          savings will vary depending on the specifications of your system and
          information.
        </div>
      </div>
    </div>
  )
}

export default Main
