import React from 'react'
import Main from './components/Main'
import Roof from './components/Roof'
import Zip from './components/Zip'
import Bill from './components/Bill'
import './App.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

function App() {
  const [formData, setFormData] = React.useState({
    roof: '',
    address: ' ',
    zip: '',
    bill: '',
    email: '',
    name: '',
    phone: ''
  })

  return (
    <Router className="App">
      {console.log('FORMDATA', formData)}
      <div>
        {console.log(formData)}
        <Switch>
          <Route path="/roof">
            <Roof setFormData={setFormData} formData={formData} />
          </Route>
          <Route path="/zip">
            <Zip setFormData={setFormData} formData={formData} />
          </Route>
          <Route path="/bill">
            <Bill />{' '}
          </Route>
          <Route path="/email">Email</Route> {/* Validate */}
          <Route path="/name">Name</Route>
          <Route path="/phone">Phone</Route>
          {/* Validdate */}
          <Route path="/verify">Verify Screen</Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
