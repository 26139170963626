import React from 'react'
import Logo from './grslogo.png'
import { Link } from 'react-router-dom'

function Zip({ formData, setFormData }) {
  return (
    <div className="bg-grGrey w-screen h-screen ease-linear">
      <div className="w-screen">
        <img className="w-64" src={Logo} alt="Green Ridge Solar" />
      </div>
      <div className="mx-24 h-full flex-1 flex-col justify-center mt-40">
        <div className="flex-1 flex-col justify-center content-center ">
          <div className="font-display text-5xl text-center text-white">
            What is your address?
          </div>
        </div>
        <div className="flex flex-col items-center justify-center my-4 mx-auto">
          <input
            placeholder="Enter Your Zip Code"
            className="w-1/3 h-12 mx-8 my-4"
            onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
            value={formData.zip}
          />
          <input
            placeholder="Enter Your Address"
            className="w-1/3 h-12 mx-8 my-4"
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            value={formData.address}
          />
        </div>
        <div className="flex justify-center my-4">
          <Link to="/bill">
            <button className="bg-grGreen hover:bg-green-500 w-48 text-white text-lg font-bold  py-2 px-8 rounded uppercase">
              Next
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Zip
